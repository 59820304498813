import './App.css';
import logo from './assets/images/logo.svg';

function App() {
  return (
    <div className='App'>
      <div className='message'>
        <img width='100%' src={logo} alt='bostec' />
        <h2 style={{ color: 'white' }}>Sitio en mantenimiento</h2>
      </div>
    </div>
  );
}

export default App;
